/* stylelint-disable declaration-no-important */

@import '../../css/tokens.css';

.select__control {
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: var(--font-family) !important;
  font-weight: 400;
  font-size: 1rem;
  color: var(--color-primary-900);
  outline: none;
  height: var(--size-field-height) !important;
  line-height: var(--size-field-height) !important;
  border-radius: var(--border-radius-xxs) !important;
  background: var(--color-neutral-100);
  border: 1px solid var(--color-primary-900) !important;
}

.select__control--is-focused {
  outline: none !important;
  border: 1px solid var(--color-primary-500) !important;
  box-shadow: none !important;
}

.select__value-container {
  padding: 0 !important;
  height: 100% !important;
}

.select__placeholder,
.select__single-value,
.select__input {
  color: var(--color-primary-900) !important;
  font-family: var(--font-family) !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  padding: 0 var(--spacing-xxs);
  white-space: nowrap;
}

.select__indicator-separator {
  display: none !important;
}

.select__menu {
  z-index: 10000 !important;
  background-color: var(--color-background-100) !important;
}

.select__option {
  white-space: nowrap !important;
  font-size: 14px !important;
  height: 35px !important;
}

.select__option--is-focused,
.select__option--is-selected {
  color: var(--color-primary-900) !important;
  background-color: var(--color-neutral-200) !important;
  border: none !important;
}
