@import '../../css/tokens.css';

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.header {
  position: relative;
  background: var(--color-primary-600);
  color: var(--color-primary-600-contrast);
  margin-top: var(--spacing-xl);
  padding: var(--spacing-sm);
}

.emojiFigure {
  position: absolute;
  top: -40px;
  left: 5px;
}

.title {
  font-family: var(--font-family);
  font-size: 1.675rem;
  padding: var(--spacing-xs);
  text-align: center;
}

.text {
  font-family: var(--font-family);
  font-size: 1rem;
  line-height: 1.25rem;
  text-align: center;
  margin-top: var(--spacing-sm);
}

.text ol {
  counter-reset: my-awesome-counter;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0 var(--spacing-xs);
  margin-top: var(--spacing-sm);
}

.text ol li {
  counter-increment: my-awesome-counter;
  display: flex;
  max-width: 400px;
  width: 100%;
  font-size: 1rem;
  text-align: left;
  align-items: center;
  justify-content: center;
  margin: var(--spacing-xxxs) 0;
  padding: var(--spacing-xs);
  background-color: var(--color-secondary-700);
  color: var(--color-secondary-700-contrast);
}

.text ol li:nth-child(odd) {
  background-color: var(--color-secondary-600);
  color: var(--color-secondary-600-contrast);
}

.text ol li::before {
  content: '0' counter(my-awesome-counter);
  font-weight: bold;
  font-size: 2.5rem;
  margin-right: var(--spacing-xs);
  line-height: 1;
}

.text ol li p {
  text-align: center;
  flex: 1;
}

.link {
  background-color: var(--color-primary-500);
  color: var(--color-primary-500-contrast);
  text-decoration: none;
  padding: var(--spacing-xs);
  margin: var(--spacing-xs);
  text-transform: uppercase;
  display: block;
}

.badges {
  margin-top: var(--spacing-xs);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.badgeLink {
  flex: 1;
  min-width: 200px;
  max-width: 260px;
  margin: var(--spacing-xs);
}

.badgeImg {
  width: 100%;
}
